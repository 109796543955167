import { toAWSDate, toAWSDateTime } from '../common/utils/awsDate';

export const AclGlobalPermissionTreeDefaults = {
  data: null,
  id: null,
};

export const AclGlobalPermissionTreeNodeDefaults = {
  actions: [],
  children: null,
};

export const AclGlobalRoleDefinitionDefaults = {
  name: null,
  roleId: null,
  statements: [],
};

export const AclGlobalRoleDefinitionsDefaults = {
  data: [],
  id: null,
};

export const AclRoleAssignmentDefaults = {
  data: [],
  id: null,
};

export const AclRoleAssignmentDataDefaults = {
  entityId: null,
  roleId: null,
};

export const AclStatementDefaults = {
  actions: [],
  inheritance: null,
};

export const AclUserRolesDefaults = {
  name: null,
  roleId: null,
};

export const AnswerQuestionByGptInputDefaults = {
  articleId: '',
  notificationId: null,
  question: '',
};

export const ApiConnectionDefaults = {
  connectionData: null,
  createdAt: null,
  createdBy: null,
  id: null,
  organizationId: null,
  pk1: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
};

export const ApiConnectionDeleteDefaults = {
  id: '',
};

export const ApiConnectionSubscriptionDefaults = {
  connectionData: null,
  createdAt: null,
  createdBy: null,
  filter: null,
  id: null,
  organizationId: null,
  pk1: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  userId: null,
};

export const ApiConnectionSubscriptionDeleteDefaults = {
  filter: null,
  id: null,
  pk1: null,
  userId: null,
};

export const ApiConnectionSubscriptionInputDefaults = {
  connectionData: null,
  createdAt: null,
  createdBy: null,
  filter: null,
  id: null,
  organizationId: null,
  pk1: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  userId: null,
};

export const ArticleDefaults = {
  advices: null,
  articleId: null,
  articlePostType: null,
  assigneeId: null,
  attachments: null,
  audienceExpertiseId: null,
  audiencePersonaId: null,
  avoid: null,
  commentId: null,
  companyKnowledgeIds: null,
  competitorsUrls: null,
  completedDate: null,
  content: null,
  createdAt: null,
  createdBy: null,
  customFields: null,
  description: null,
  directReaderAddressing: null,
  doneAdvices: null,
  dueDate: null,
  exploreInputs: null,
  firstPersonPerspective: null,
  focusKeyword: null,
  guidelines: null,
  hasTopRankings: null,
  id: null,
  imageGuidelines: null,
  inputTopics: null,
  labelIds: null,
  name: null,
  neuraverseTopicData: null,
  pk1: null,
  preferences: null,
  priority: null,
  projectId: null,
  publicationDate: null,
  readingResources: null,
  requestOrigin: null,
  resolved: null,
  rscore: null,
  sk1: null,
  sk2: null,
  state: null,
  title: null,
  toneAndVoiceId: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  urlIndexedAt: null,
  urlPath: null,
  urlStatus: null,
  versionCreatorId: null,
  versionDate: null,
  versionId: null,
  versionName: null,
  wordCountPerArticle: null,
};

export const ArticleBriefDefaults = {
  answered: null,
  articleId: null,
  content: null,
  createdAt: null,
  createdBy: null,
  description: null,
  hidden: null,
  id: null,
  index: null,
  keywordVariations: null,
  keywords: null,
  level: null,
  pk1: null,
  questions: null,
  relevance: null,
  required: null,
  sk1: null,
  title: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  volumes: null,
};

export const ArticleBriefDeleteDefaults = {
  id: '',
};

export const ArticleBriefSubscriptionDefaults = {
  answered: null,
  articleId: null,
  content: null,
  createdAt: null,
  createdBy: null,
  description: null,
  hidden: null,
  id: null,
  index: null,
  keywordVariations: null,
  keywords: null,
  level: null,
  organizationId: null,
  pk1: null,
  questions: null,
  relevance: null,
  required: null,
  sk1: null,
  title: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  userId: null,
  volumes: null,
};

export const ArticleBriefSubscriptionDeleteDefaults = {
  pk1: null,
  sk1: null,
  type: null,
  userId: null,
};

export const ArticleBriefSubscriptionInputDefaults = {
  answered: null,
  articleId: null,
  content: null,
  createdAt: null,
  createdBy: null,
  description: null,
  hidden: null,
  id: null,
  index: null,
  keywordVariations: null,
  keywords: null,
  level: null,
  organizationId: null,
  pk1: null,
  questions: null,
  relevance: null,
  required: null,
  sk1: null,
  title: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  userId: null,
  volumes: null,
};

export const ArticleByGptInputDefaults = {
  assigneeId: '',
  dueDate: null,
  isUserCreatedDirection: null,
  limit: null,
  projectId: '',
  publicationDate: null,
  title: null,
};

export const ArticleCommentDefaults = {
  articleId: '',
  commentCreator: null,
  commentId: null,
  createdAt: toAWSDateTime(new Date()),
  createdBy: null,
  id: '',
  pk1: '',
  requestOrigin: null,
  resolved: null,
  sk1: '',
  updateToken: null,
  updatedAt: toAWSDateTime(new Date()),
  updatedBy: null,
};

export const ArticleCommentDeleteDefaults = {
  id: '',
};

export const ArticleCountByStateDefaults = {
  BRIEF: 0,
  DONE: 0,
  ONHOLD: 0,
  REVIEW: 0,
  TODO: 0,
  WRITE: 0,
};

export const ArticleDeleteDefaults = {
  id: '',
};

export const ArticleDocumentDefaults = {
  content: null,
  createdAt: toAWSDateTime(new Date()),
  createdBy: null,
  id: '',
  pk1: '',
  requestOrigin: null,
  sk1: '',
  sk2: null,
  updateToken: null,
  updatedAt: toAWSDateTime(new Date()),
  updatedBy: null,
  versionCreatorId: null,
  versionDate: null,
  versionId: 0,
  versionName: null,
};

export const ArticleGuidelinesDefaults = {
  createdAt: toAWSDateTime(new Date()),
  createdBy: null,
  guidelines: null,
  id: '',
  pk1: '',
  requestOrigin: null,
  sk1: '',
  sk2: null,
  updateToken: null,
  updatedAt: toAWSDateTime(new Date()),
  updatedBy: null,
  versionCreatorId: null,
  versionDate: null,
  versionId: 0,
  versionName: null,
};

export const ArticleHeadlineDefaults = {
  articleId: '',
  createdAt: '',
  createdBy: null,
  description: '',
  id: '',
  index: 0,
  keywords: [],
  level: 0,
  questions: [],
  title: '',
  updateToken: null,
  updatedAt: '',
  updatedBy: null,
};

export const ArticleHeadlineDeleteDefaults = {
  id: '',
};

export const ArticleHeadlineInputDefaults = {
  articleId: '',
  createdAt: '',
  createdBy: null,
  description: '',
  id: '',
  index: 0,
  keywords: [],
  level: 0,
  questions: [],
  title: '',
  updateToken: null,
  updatedAt: '',
  updatedBy: null,
};

export const ArticleImageGuidelinesDefaults = {
  allowGenAI: false,
  customFields: null,
};

export const ArticleImageGuidelinesInputDefaults = {
  allowGenAI: false,
  customFields: null,
};

export const ArticleKeywordDefaults = {
  articleId: '',
  content: '',
  createdAt: '',
  createdBy: null,
  hidden: null,
  id: '',
  keywordVariations: null,
  relevance: null,
  required: false,
  updateToken: null,
  updatedAt: '',
  updatedBy: null,
  volumes: null,
};

export const ArticleKeywordDeleteDefaults = {
  id: '',
};

export const ArticleKeywordInputDefaults = {
  articleId: '',
  content: '',
  createdAt: null,
  createdBy: null,
  hidden: null,
  id: '',
  relevance: null,
  required: false,
  type: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  volumes: null,
};

export const ArticleKeywordVariationsDefaults = {
  abbreviationsOrAcronyms: [],
  closelyRelatedCompoundNouns: [],
  rootForms: [],
  separationsByHyphens: [],
  singularAndPlural: [],
  superlatives: [],
  synonyms: [],
  synonymsSingularAndPlural: [],
  variationsUsingConnectingPrepositionOptions: [],
  verbsAndNouns: [],
  verbsAndNounsSingularAndPlural: [],
};

export const ArticleKeywordVariationsInputDefaults = {
  abbreviationsOrAcronyms: [],
  closelyRelatedCompoundNouns: [],
  rootForms: [],
  separationsByHyphens: [],
  singularAndPlural: [],
  superlatives: [],
  synonyms: [],
  synonymsSingularAndPlural: [],
  variationsUsingConnectingPrepositionOptions: [],
  verbsAndNouns: [],
  verbsAndNounsSingularAndPlural: [],
};

export const ArticleMetadataDefaults = {
  articleDocument: null,
  articleGuidelines: null,
  articleRScore: null,
  articleRootData: null,
  assignee: null,
  assigneeId: '',
  audienceExpertiseId: null,
  audiencePersonaId: null,
  avoid: null,
  companyKnowledgeIds: null,
  competitorsUrls: null,
  completedDate: null,
  createdAt: toAWSDateTime(new Date()),
  createdBy: null,
  customFields: null,
  description: null,
  dueDate: null,
  exploreInputs: null,
  firstPersonPerspective: null,
  focusKeyword: null,
  hasTopRankings: null,
  id: '',
  inputTopics: null,
  name: null,
  neuraverseTopicData: null,
  pk1: '',
  project: null,
  projectId: '',
  publicationDate: null,
  readingResources: null,
  requestOrigin: null,
  selectedProjectBriefDocuments: null,
  selectedProjectLabels: null,
  sk1: '',
  sk2: null,
  title: null,
  toneAndVoiceId: null,
  updateToken: null,
  updatedAt: toAWSDateTime(new Date()),
  updatedBy: null,
  urlPath: null,
  userArticle: null,
  versionCreator: null,
  versionCreatorId: null,
  versionDate: null,
  versionId: 0,
  versionName: null,
  wordCountPerArticle: null,
};

export const ArticleNeuraverseTopicDataDefaults = {
  articleDirection: null,
  articleLengthSuggestion: null,
  topicId: '',
  topicNames: [],
};

export const ArticleNeuraverseTopicDataInputDefaults = {
  articleDirection: null,
  articleLengthSuggestion: null,
  topicId: '',
  topicNames: [],
};

export const ArticleNotificationSettingsDefaults = {
  email: false,
  inapp: false,
  notifyAll: false,
  notifyIfAssignee: false,
  notifyIfCreator: false,
};

export const ArticleNotificationSettingsInputDefaults = {
  email: false,
  inapp: false,
  notifyAll: false,
  notifyIfAssignee: false,
  notifyIfCreator: false,
};

export const ArticlePostDefaults = {
  advices: null,
  attachments: null,
  content: null,
  createdAt: toAWSDateTime(new Date()),
  createdBy: null,
  id: '',
  name: null,
  pk1: '',
  requestOrigin: null,
  sk1: '',
  updateToken: null,
  updatedAt: toAWSDateTime(new Date()),
  updatedBy: null,
};

export const ArticlePostAdvicesDefaults = {
  advices: null,
  createdAt: toAWSDateTime(new Date()),
  createdBy: null,
  id: '',
  pk1: '',
  requestOrigin: null,
  sk1: '',
  updateToken: null,
  updatedAt: toAWSDateTime(new Date()),
  updatedBy: null,
};

export const ArticlePostDeleteDefaults = {
  id: '',
};

export const ArticleQuestionDefaults = {
  answered: null,
  articleId: '',
  content: '',
  createdAt: '',
  createdBy: null,
  hidden: null,
  id: '',
  updateToken: null,
  updatedAt: '',
  updatedBy: null,
};

export const ArticleQuestionDeleteDefaults = {
  id: '',
};

export const ArticleQuestionInputDefaults = {
  answered: null,
  articleId: '',
  content: '',
  createdAt: null,
  createdBy: null,
  hidden: null,
  id: '',
  type: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
};

export const ArticleRScoreDefaults = {
  createdAt: toAWSDateTime(new Date()),
  createdBy: null,
  doneAdvices: null,
  id: '',
  pk1: '',
  requestOrigin: null,
  rscore: null,
  sk1: '',
  sk2: null,
  updateToken: null,
  updatedAt: toAWSDateTime(new Date()),
  updatedBy: null,
  versionCreatorId: null,
  versionDate: null,
  versionId: 0,
  versionName: null,
};

export const ArticleRootDataDefaults = {
  createdAt: toAWSDateTime(new Date()),
  createdBy: null,
  id: '',
  labelIds: null,
  pk1: '',
  priority: null,
  requestOrigin: null,
  sk1: '',
  sk2: null,
  updateToken: null,
  updatedAt: toAWSDateTime(new Date()),
  updatedBy: null,
  urlIndexedAt: null,
  urlStatus: null,
};

export const ArticleStatisticsDefaults = {
  articleCountInProgress: 0,
  completedArticlesThisMonth: 0,
};

export const ArticleSubscriptionDefaults = {
  advices: null,
  articleId: null,
  articlePostType: null,
  assigneeId: null,
  attachments: null,
  audienceExpertiseId: null,
  audiencePersonaId: null,
  avoid: null,
  commentId: null,
  companyKnowledgeIds: null,
  competitorsUrls: null,
  completedDate: null,
  content: null,
  createdAt: null,
  createdBy: null,
  customFields: null,
  description: null,
  directReaderAddressing: null,
  doneAdvices: null,
  dueDate: null,
  exploreInputs: null,
  filter: null,
  firstPersonPerspective: null,
  focusKeyword: null,
  guidelines: null,
  hasTopRankings: null,
  id: null,
  imageGuidelines: null,
  inputTopics: null,
  labelIds: null,
  name: null,
  neuraverseTopicData: null,
  pk1: null,
  preferences: null,
  priority: null,
  projectId: null,
  publicationDate: null,
  readingResources: null,
  requestOrigin: null,
  resolved: null,
  rscore: null,
  sk1: null,
  sk2: null,
  state: null,
  title: null,
  toneAndVoiceId: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  urlIndexedAt: null,
  urlPath: null,
  urlStatus: null,
  userId: null,
  versionCreatorId: null,
  versionDate: null,
  versionId: null,
  versionName: null,
  wordCountPerArticle: null,
};

export const ArticleSubscriptionDeleteDefaults = {
  id: null,
  pk1: null,
  type: null,
  userId: null,
};

export const ArticleSubscriptionInputDefaults = {
  advices: null,
  articleId: null,
  articlePostType: null,
  assigneeId: null,
  attachments: null,
  audienceExpertiseId: null,
  audiencePersonaId: null,
  avoid: null,
  commentId: null,
  companyKnowledgeIds: null,
  competitorsUrls: null,
  completedDate: null,
  content: null,
  createdAt: null,
  createdBy: null,
  customFields: null,
  description: null,
  directReaderAddressing: null,
  doneAdvices: null,
  dueDate: null,
  exploreInputs: null,
  filter: null,
  firstPersonPerspective: null,
  focusKeyword: null,
  guidelines: null,
  hasTopRankings: null,
  id: null,
  imageGuidelines: null,
  inputTopics: null,
  labelIds: null,
  name: null,
  neuraverseTopicData: null,
  pk1: null,
  preferences: null,
  priority: null,
  projectId: null,
  publicationDate: null,
  readingResources: null,
  requestOrigin: null,
  resolved: null,
  rscore: null,
  sk1: null,
  sk2: null,
  state: null,
  title: null,
  toneAndVoiceId: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  urlIndexedAt: null,
  urlPath: null,
  urlStatus: null,
  userId: null,
  versionCreatorId: null,
  versionDate: null,
  versionId: null,
  versionName: null,
  wordCountPerArticle: null,
};

export const ArticlesWithSameFocusKeywordDefaults = {
  id: '',
  name: null,
};

export const Auth0Defaults = {
  id: '',
};

export const Auth0InputDefaults = {
  id: '',
};

export const Auth0OrganizationDefaults = {
  branding: null,
  display_name: null,
  id: '',
  name: null,
};

export const Auth0OrganizationBrandingDefaults = {
  colors: null,
  logo_url: null,
};

export const Auth0OrganizationBrandingColorsDefaults = {
  page_background: null,
  primary: null,
};

export const Auth0OrganizationBrandingInputDefaults = {
  logoUrl: null,
  pageBackgroundColor: '',
  primaryColor: '',
};

export const BriefExportInputDefaults = {
  articleId: '',
  exportId: null,
};

export const BrokenArticleUrlDefaults = {
  articleId: '',
  articleTitle: '',
  id: '',
  status: null,
  url: '',
};

export const BrokenArticleUrlsOutputDefaults = {
  items: [],
};

export const BrokenLinkDefaults = {
  articleId: '',
  articleTitle: '',
  id: '',
  pk1: '',
  sk1: '',
  status: null,
  updatedAt: null,
  url: '',
};

export const BrokenLinksInArticleDefaults = {
  items: null,
};

export const BrokenLinksInProjectOutputDefaults = {
  items: [],
};

export const BulkExportInputDefaults = {
  articleIds: [],
  exportId: null,
  projectId: '',
};

export const BulkWordPressExportInputDefaults = {
  articleIds: [],
  categoryId: null,
  exportId: null,
  projectId: '',
};

export const CompareVersionsInputDefaults = {
  articleId1: '',
  articleId2: '',
};

export const CompareVersionsOutputDefaults = {
  comparedHtml: '',
};

export const ConclusionByGptInputDefaults = {
  articleId: '',
};

export const ContentByGptInputDefaults = {
  articleId: '',
  updateToken: null,
};

export const ContentCategoriesInputDefaults = {
  timeFrame: 0,
  topics: null,
};

export const ContentCategoriesOutputDefaults = {
  contentCategories: [],
};

export const ContentCategoryDefaults = {
  id: '',
  subCategories: [],
};

export const ContinueTextByGptInputDefaults = {
  articleId: '',
  notificationId: null,
  text: '',
};

export const CoreWebVitalDefaults = {};

export const CoreWebVitalsDefaults = {};

export const CreateAnalyticsLogInputDefaults = {};

export const CreateApiConnectionInputDefaults = {
  connectionData: null,
  createdAt: null,
  createdBy: null,
  id: null,
  organizationId: '',
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
};

export const CreateArticleBriefInputDefaults = {
  answered: null,
  articleId: '',
  content: '',
  createdAt: '',
  createdBy: null,
  description: '',
  hidden: null,
  id: '',
  index: 0,
  keywordVariations: null,
  keywords: [],
  level: 0,
  questions: [],
  relevance: null,
  required: false,
  title: '',
  updateToken: null,
  updatedAt: '',
  updatedBy: null,
  volumes: null,
};

export const CreateArticleByNeuraverseInputDefaults = {
  assigneeId: '',
  audienceExpertiseId: null,
  audiencePersonaId: null,
  avoid: null,
  companyKnowledgeIds: null,
  competitorsUrls: null,
  completedDate: null,
  content: null,
  customFields: null,
  description: null,
  doneAdvices: null,
  dueDate: null,
  exploreInputs: null,
  firstPersonPerspective: null,
  focusKeyword: '',
  guidelines: null,
  hasTopRankings: null,
  imageGuidelines: null,
  inputTopics: null,
  isUserCreatedDirection: null,
  labelIds: null,
  name: '',
  priority: null,
  projectId: '',
  publicationDate: null,
  readingResources: null,
  rscore: null,
  title: null,
  toneAndVoiceId: null,
  updateToken: null,
  urlIndexedAt: null,
  urlPath: null,
  versionCreatorId: null,
  versionDate: null,
  versionId: 0,
  versionName: null,
  wordCountPerArticle: null,
};

export const CreateArticleCommentInputDefaults = {
  articleId: '',
  commentId: null,
  id: null,
  resolved: null,
  updateToken: null,
};

export const CreateArticleHeadlineRefInputDefaults = {
  headlineId: '',
  id: '',
  updateToken: null,
};

export const CreateArticleHeadlineRefsInputDefaults = {
  headlineId: '',
  items: [],
  updateToken: null,
};

export const CreateArticleHeadlineRefsItemInputDefaults = {
  id: '',
};

export const CreateArticleInputDefaults = {
  assigneeId: '',
  audienceExpertiseId: null,
  audiencePersonaId: null,
  avoid: null,
  companyKnowledgeIds: null,
  competitorsUrls: null,
  completedDate: null,
  content: null,
  customFields: null,
  description: null,
  doneAdvices: null,
  dueDate: null,
  exploreInputs: null,
  firstPersonPerspective: null,
  focusKeyword: null,
  guidelines: null,
  hasTopRankings: null,
  imageGuidelines: null,
  inputTopics: null,
  labelIds: null,
  name: null,
  neuraverseTopicData: null,
  priority: null,
  projectId: '',
  publicationDate: null,
  readingResources: null,
  rscore: null,
  title: null,
  toneAndVoiceId: null,
  updateToken: null,
  urlIndexedAt: null,
  urlPath: null,
  versionCreatorId: null,
  versionDate: null,
  versionId: 0,
  versionName: null,
  wordCountPerArticle: null,
};

export const CreateArticlePostInputDefaults = {
  articleId: '',
  content: null,
  id: null,
  name: null,
  updateToken: null,
};

export const CreateInvitationInputDefaults = {
  email: '',
  projectInvitations: [],
};

export const CreateInvitationsInputDefaults = {
  invitations: [],
  inviter: '',
};

export const CreateLinkedinTokenInputDefaults = {
  code: '',
  projectId: '',
};

export const CreateNeuraverseProjectInputDefaults = {
  name: '',
  neuraverseId: '',
  organizationId: '',
};

export const CreateNotificationInputDefaults = {
  createdAt: null,
  data: null,
  entityId: null,
  id: null,
  initiatedBy: null,
  notificationId: null,
  read: null,
  ttl: null,
  updatedAt: null,
};

export const CreateOneClickAnalysisInputDefaults = {
  projectId: '',
};

export const CreateOrganizationInputDefaults = {
  auth0Id: null,
  branding: null,
  displayName: '',
  inviteeEmails: [],
  name: '',
  project: null,
};

export const CreateOrganizationProjectNeuraverseInputDefaults = {
  name: '',
  neuraverseId: '',
};

export const CreateProjectBriefDocumentInputDefaults = {
  content: '',
  id: '',
  name: '',
  projectId: '',
  selected: null,
  updateToken: null,
};

export const CreateProjectInputDefaults = {
  archived: null,
  avatar: null,
  color: null,
  country: null,
  description: null,
  domain: null,
  gscConnected: null,
  gscConnectionId: null,
  linkedinConnected: null,
  linkedinConnectionId: null,
  linkedinOrgId: null,
  name: null,
  neuraverseId: null,
  organizationId: '',
  topicInspirations: null,
  ttl: null,
  wpConnected: null,
  wpConnectionId: null,
};

export const CreateProjectLabelInputDefaults = {
  color: '',
  id: '',
  name: '',
  projectId: '',
  updateToken: null,
};

export const CreateUiViewInputDefaults = {
  calendarVisibility: null,
  companyPriority: null,
  entityId: '',
  expanded: null,
  filtering: null,
  index: 0,
  name: null,
  priority: null,
  searchTerm: null,
  showWinnersLosers: null,
  sorting: null,
  tab: null,
  tableId: '',
  updateToken: null,
  visibility: null,
};

export const CreateUserInputDefaults = {
  avatar: null,
  color: null,
  createdAt: null,
  email: null,
  firstName: null,
  id: null,
  lastName: null,
  lastReadNewsFeedPubDate: null,
  nickname: '',
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  userOrgData: null,
};

export const CreateVersionInputDefaults = {
  articleId: '',
};

export const CreatedOrganizationDefaults = {
  auth0Organization: null,
  createdAt: toAWSDateTime(new Date()),
  id: '',
  inviteeEmails: null,
  name: '',
};

export const CustomFieldDefaults = {
  key: null,
  value: null,
};

export const CustomFieldInputDefaults = {
  key: null,
  value: null,
};

export const DeleteApiConnectionInputDefaults = {
  projectId: '',
};

export const DeleteArticleBriefInputDefaults = {
  id: '',
};

export const DeleteArticleCommentInputDefaults = {
  id: '',
};

export const DeleteArticleHeadlineInputDefaults = {
  id: '',
};

export const DeleteArticleHeadlineRefInputDefaults = {
  headlineId: '',
  id: '',
  updateToken: null,
};

export const DeleteArticleHeadlinesInputDefaults = {
  articleId: '',
  headlines: [],
};

export const DeleteArticleInputDefaults = {
  id: '',
};

export const DeleteArticleKeywordInputDefaults = {
  id: '',
};

export const DeleteArticlePostInputDefaults = {
  id: '',
};

export const DeleteArticleQuestionInputDefaults = {
  id: '',
};

export const DeleteInvitationInputDefaults = {
  invitationId: '',
};

export const DeleteNotificationInputDefaults = {
  id: '',
};

export const DeleteOrganizationInputDefaults = {
  id: '',
};

export const DeleteProjectBriefDocumentInputDefaults = {
  id: '',
};

export const DeleteProjectInputDefaults = {
  id: '',
};

export const DeleteProjectLabelInputDefaults = {
  id: '',
};

export const DeleteUiViewInputDefaults = {
  id: '',
};

export const DeleteUserInputDefaults = {
  userId: null,
};

export const DeleteVersionInputDefaults = {
  articleId: '',
};

export const ExploreDefaults = {
  contentCategory: null,
  currentTab: null,
  searchTerms: null,
  searchedOnce: null,
  subcategory: null,
  timeRange: null,
  variety: null,
};

export const ExploreInputDefaults = {
  contentCategory: null,
  currentTab: null,
  searchTerms: null,
  searchedOnce: null,
  subcategory: null,
  timeRange: null,
  variety: null,
};

export const FocusKeywordsByGptInputDefaults = {
  articleId: '',
  notificationId: '',
};

export const GscAnalyticsDefaults = {
  averageGoogleRanking: null,
  averageGoogleRankingChange: null,
  clicks: null,
  ctr: null,
  impressions: null,
  items: null,
  previousAverageGoogleRanking: null,
  publishedArticles: null,
  searchVolume: null,
  updatesGoogle: null,
};

export const GscArticleAnalyticsInputDefaults = {
  articleId: '',
  end: toAWSDate(new Date()),
  projectId: '',
  start: toAWSDate(new Date()),
};

export const GscArticleByKeywordDefaults = {
  articleId: null,
  averageGoogleRanking: null,
  clicks: null,
  ctr: null,
  impressions: null,
  searchVolume: null,
  title: null,
};

export const GscArticleByProjectDefaults = {
  articleId: null,
  averageGoogleRanking: null,
  averageGoogleRankingChange: null,
  clicks: null,
  ctr: null,
  id: null,
  impressions: null,
  keywords: null,
  previousAverageGoogleRanking: null,
  publicationDate: null,
  rscore: null,
  title: null,
};

export const GscArticleKeywordDefaults = {
  averageGoogleRanking: null,
  clicks: null,
  ctr: null,
  impressions: null,
  keyword: '',
};

export const GscArticlesByKeywordDefaults = {
  items: null,
};

export const GscArticlesByKeywordInputDefaults = {
  articleIds: [],
  end: toAWSDate(new Date()),
  keyword: '',
  projectId: '',
  start: toAWSDate(new Date()),
};

export const GscArticlesByProjectDefaults = {
  items: null,
  nextToken: null,
};

export const GscArticlesByProjectInputDefaults = {
  end: toAWSDate(new Date()),
  projectId: '',
  start: toAWSDate(new Date()),
};

export const GscConsentScreenUriDefaults = {
  signInUri: '',
};

export const GscConsentScreenUriInputDefaults = {};

export const GscCreateTokenInputDefaults = {
  code: '',
  domain: '',
  projectId: '',
};

export const GscDataDefaults = {
  data: [],
  resolution: null,
};

export const GscDataPointDefaults = {
  date: toAWSDate(new Date()),
  title: null,
  url: null,
  value: null,
};

export const GscDomainDefaults = {
  permissionLevel: '',
  siteUrl: '',
};

export const GscDomainsDefaults = {
  items: null,
};

export const GscDomainsInputDefaults = {
  projectId: '',
};

export const GscGoogleUpdateDataPointDefaults = {
  date: toAWSDate(new Date()),
  description: '',
  id: '',
  title: '',
  url: '',
};

export const GscGoogleUpdatesDefaults = {
  data: null,
};

export const GscKeywordSearchVolumeDefaults = {
  growth: null,
};

export const GscKeywordsByArticleDefaults = {
  items: null,
};

export const GscKeywordsByArticleInputDefaults = {
  articleId: '',
  end: toAWSDate(new Date()),
  start: toAWSDate(new Date()),
};

export const GscKeywordsByProjectDefaults = {
  items: null,
};

export const GscKeywordsByProjectInputDefaults = {
  articleIds: null,
  end: toAWSDate(new Date()),
  projectId: '',
  scope: null,
  start: toAWSDate(new Date()),
};

export const GscProjectAnalyticsInputDefaults = {
  end: toAWSDate(new Date()),
  projectId: '',
  scope: null,
  start: toAWSDate(new Date()),
};

export const GscProjectKeywordDefaults = {
  articleIds: null,
  averageGoogleRanking: null,
  clicks: null,
  ctr: null,
  impressions: null,
  keyword: '',
  searchVolume: null,
};

export const GscPublishedArticleDataPointDefaults = {
  date: toAWSDateTime(new Date()),
  id: '',
  title: null,
};

export const GscPublishedArticlesDefaults = {
  data: null,
};

export const GscTopChangingArticleDefaults = {
  articleId: '',
  currentAverageGoogleRanking: 0,
  previousAverageGoogleRanking: 0,
  title: '',
  value: 0,
};

export const GscTopChangingArticlesDefaults = {
  items: null,
};

export const GenerateHeadlineByGptInputDefaults = {
  articleId: '',
  notificationId: null,
  text: '',
};

export const GenerateHeadlineSuggestionsByGptInputDefaults = {
  articleId: '',
  index: 0,
  level: 0,
  notificationId: null,
  outline: [],
};

export const GenerateImageByDallEInputDefaults = {
  articleId: '',
  fileName: '',
  id: '',
  notificationId: null,
  prompt: null,
  text: null,
};

export const GenerateKeywordVariationsByGptInputDefaults = {
  articleTitle: '',
  keywordId: '',
  notificationId: null,
};

export const GenerateMetadataByGptInputDefaults = {
  articleId: '',
  notificationId: null,
};

export const GetArticleCommentInputDefaults = {
  id: '',
};

export const GetArticlePostInputDefaults = {
  id: '',
};

export const GetGscConnectionStatusInputDefaults = {
  projectId: '',
};

export const GetGoogleTopRankingsInputDefaults = {
  articleId: '',
  searchTerm: '',
};

export const GetLinkedinConnectionStatusInputDefaults = {
  projectId: '',
};

export const GetNVeFilterDataInputDefaults = {
  competitorTopics: null,
  id: '',
  myTopics: null,
  onlySaved: null,
  topicGaps: null,
};

export const GetNVeMetadataInputDefaults = {
  id: '',
};

export const GetNVeTopicInputDefaults = {
  id: '',
};

export const GetNotificationInputDefaults = {
  id: '',
};

export const GetOneClickAnalysisInputDefaults = {
  projectId: '',
};

export const GetProjectBriefDocumentUsageCountInputDefaults = {
  id: '',
};

export const GetProjectLabelUsageCountInputDefaults = {
  id: '',
};

export const GetTurnServerCredentialsInputDefaults = {
  articleId: '',
};

export const GetTurnServerCredentialsResponseDefaults = {
  iceServers: [],
};

export const GetWordpressConnectionInputDefaults = {
  projectId: '',
};

export const GetWordpressConnectionResponseDefaults = {
  endpoint: '',
  errors: null,
  username: '',
};

export const GoogleSearchResultDefaults = {
  outline: null,
  position: 0,
  summary: null,
  title: '',
  url: '',
};

export const GoogleSearchResultHeadlineDefaults = {
  level: 0,
  title: '',
};

export const GoogleSearchUpdateDefaults = {
  date: toAWSDate(new Date()),
  id: '',
  title: '',
  url: '',
};

export const GoogleTopRankingDefaults = {
  averageWordCount: null,
  locale: null,
  searchResults: null,
  searchTerm: '',
  ttl: null,
  urlCount: null,
};

export const GscConnectionCredentialsDefaults = {
  domain: '',
  refreshToken: '',
};

export const GscConnectionStatusResponseDefaults = {};

export const HtmlExportDefaults = {
  key: null,
  name: null,
};

export const HtmlExportInputDefaults = {
  articleId: '',
};

export const IceServerDefaults = {
  credential: null,
  url: null,
  urls: null,
  username: null,
};

export const ImportArticleInputDefaults = {
  assigneeId: '',
  dueDate: null,
  projectId: '',
  publicationDate: null,
  title: '',
  url: '',
};

export const IndexedArticleDefaults = {
  id: '',
  name: null,
  urlIndexedAt: '',
};

export const InvitationDefaults = {
  app_metadata: null,
  client_id: null,
  connection_id: null,
  created_at: null,
  expires_at: null,
  id: null,
  invitation_url: null,
  invitee: null,
  inviter: null,
  organization_id: null,
  roles: null,
  ticket_id: null,
  user_metadata: null,
};

export const InvitationInviteeDefaults = {
  email: '',
};

export const InvitationInviterDefaults = {
  name: '',
};

export const InvitationsDefaults = {
  items: null,
};

export const KeywordVolumeDefaults = {
  articleIds: null,
  createdAt: toAWSDate(new Date()),
  id: '',
  keyword: null,
  updatedAt: toAWSDate(new Date()),
  volumes: null,
};

export const LastProductUpdateDefaults = {
  excerpt: '',
  pubDate: toAWSDateTime(new Date()),
  url: '',
};

export const LatestIndexedArticlesDefaults = {
  articles: [],
};

export const LinkNeuraverseToProjectInputDefaults = {
  id: '',
  neuraverseId: '',
};

export const LinkedInPostByGptInputDefaults = {
  articleId: '',
  id: null,
  notificationId: null,
  selectedText: null,
};

export const LinkedinConnectionStatusResponseDefaults = {};

export const LinkedinConsentScreenUriDefaults = {
  signInUri: '',
};

export const LinkedinConsentScreenUriInputDefaults = {};

export const LinkedinOrganizationDefaults = {
  id: '',
  name: '',
};

export const ListApiConnectionsDefaults = {
  items: [],
  nextToken: null,
};

export const ListArticleBriefsDefaults = {
  items: [],
  nextToken: null,
};

export const ListArticleCommentInputDefaults = {
  articleId: '',
};

export const ListArticleHeadlinesTypeDefaults = {
  items: [],
};

export const ListArticleImagesInputDefaults = {
  articleId: '',
};

export const ListArticlePostsInputDefaults = {
  articleId: '',
};

export const ListArticlePostsOutputDefaults = {
  items: [],
  nextToken: null,
};

export const ListArticlesDefaults = {
  items: [],
  nextToken: null,
};

export const ListArticlesInputFilterDefaults = {
  assigneeId: null,
  audienceExpertiseId: null,
  audiencePersonaId: null,
  avoid: null,
  companyKnowledgeIds: null,
  competitorsUrls: null,
  completedDate: null,
  createdAt: null,
  createdBy: null,
  description: null,
  directReaderAddressing: null,
  dueDate: null,
  firstPersonPerspective: null,
  focusKeyword: null,
  hasTopRankings: null,
  id: null,
  inputTopics: null,
  name: null,
  pk1: null,
  projectId: null,
  publicationDate: null,
  readingResources: null,
  requestOrigin: null,
  sk1: null,
  sk2: null,
  state: null,
  title: null,
  toneAndVoiceId: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  urlPath: null,
  versionCreatorId: null,
  versionDate: null,
  versionId: null,
  versionName: null,
  wordCountPerArticle: null,
};

export const ListArticlesWithSameFocusKeywordDefaults = {
  items: [],
};

export const ListArticlesWithSameFocusKeywordInputDefaults = {
  articleId: '',
  focusKeyword: '',
};

export const ListBrokenArticleUrlsInputDefaults = {
  projectId: '',
};

export const ListBrokenLinksInArticleInputDefaults = {
  articleId: '',
};

export const ListBrokenLinksInProjectInputDefaults = {
  projectId: '',
};

export const ListCommentsOutputDefaults = {
  items: [],
  nextToken: null,
};

export const ListGscTopChangingArticlesInputDefaults = {
  projectId: '',
};

export const ListLatestIndexedArticlesInputDefaults = {
  projectId: '',
};

export const ListLinkedinOrganizationsInputDefaults = {
  projectId: '',
};

export const ListNVeCompaniesInputDefaults = {
  id: '',
};

export const ListNVeTopicArticlesInputDefaults = {
  id: '',
  lastEvaluatedKey: null,
  limit: 0,
  onlySaved: null,
  searchLocale: null,
  searchTerm: null,
  types: [],
};

export const ListNVeTopicCompanyDataInputDefaults = {
  id: '',
  name: '',
};

export const ListNVeTopicsInputDefaults = {
  competitorTopics: null,
  id: '',
  myTopics: null,
  onlySaved: null,
  topicGaps: null,
};

export const ListNotificationsDefaults = {
  items: [],
  nextToken: null,
};

export const ListOrganizationsDefaults = {
  items: [],
  nextToken: null,
};

export const ListProjectsDefaults = {
  items: [],
  nextToken: null,
};

export const ListProjectsInputFilterDefaults = {
  archived: null,
  avatar: null,
  color: null,
  country: null,
  createdAt: null,
  createdBy: null,
  description: null,
  domain: null,
  gscConnected: null,
  gscConnectionId: null,
  id: null,
  linkedinConnected: null,
  linkedinConnectionId: null,
  linkedinOrgId: null,
  locale: null,
  name: null,
  neuraverseId: null,
  organizationId: null,
  pk1: null,
  sk1: null,
  topicInspirations: null,
  ttl: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  wpConnected: null,
  wpConnectionId: null,
};

export const ListUiViewsDefaults = {
  items: [],
  nextToken: null,
};

export const ListUsersDefaults = {
  items: [],
  nextToken: null,
};

export const ListVersionsInputDefaults = {
  articleId: '',
};

export const ListWordpressCategoriesInputDefaults = {
  projectId: '',
};

export const ListWordpressCategoriesResponseDefaults = {
  items: null,
};

export const LoginInputDefaults = {
  nickname: '',
  organizationId: '',
  projectInvitations: null,
  roleId: null,
};

export const MatchKeywordsAndQuestionsByGptInputDefaults = {
  articleId: '',
  notificationId: null,
};

export const ModelApiConnectionFilterInputDefaults = {
  connectionData: null,
  organizationId: null,
  serviceName: null,
  updateToken: null,
};

export const ModelArticleBriefFilterInputDefaults = {
  answered: null,
  articleId: null,
  content: null,
  createdAt: null,
  createdBy: null,
  description: null,
  hidden: null,
  id: null,
  index: null,
  level: null,
  relevance: null,
  required: null,
  title: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
};

export const ModelArticleFilterInputDefaults = {
  advices: null,
  articleId: null,
  articlePostType: null,
  assigneeId: null,
  attachments: null,
  audienceExpertiseId: null,
  audiencePersonaId: null,
  avoid: null,
  commentId: null,
  companyKnowledgeIds: null,
  competitorsUrls: null,
  completedDate: null,
  content: null,
  createdAt: null,
  createdBy: null,
  description: null,
  directReaderAddressing: null,
  doneAdvices: null,
  dueDate: null,
  firstPersonPerspective: null,
  focusKeyword: null,
  guidelines: null,
  hasTopRankings: null,
  id: null,
  inputTopics: null,
  labelIds: null,
  name: null,
  preferences: null,
  priority: null,
  projectId: null,
  publicationDate: null,
  readingResources: null,
  requestOrigin: null,
  resolved: null,
  rscore: null,
  sk2: null,
  state: null,
  title: null,
  toneAndVoiceId: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  urlIndexedAt: null,
  urlPath: null,
  urlStatus: null,
  versionCreatorId: null,
  versionDate: null,
  versionId: null,
  versionName: null,
  wordCountPerArticle: null,
};

export const ModelBooleanFilterInputDefaults = {
  eq: null,
  ne: null,
};

export const ModelFloatFilterInputDefaults = {
  between: null,
  eq: null,
  ge: null,
  gt: null,
  le: null,
  lt: null,
  ne: null,
};

export const ModelFloatKeyConditionInputDefaults = {
  between: null,
  eq: null,
  ge: null,
  gt: null,
  le: null,
  lt: null,
};

export const ModelIdFilterInputDefaults = {
  beginsWith: null,
  between: null,
  contains: null,
  eq: null,
  ge: null,
  gt: null,
  le: null,
  lt: null,
  ne: null,
  notContains: null,
};

export const ModelIdKeyConditionInputDefaults = {
  beginsWith: null,
  between: null,
  eq: null,
  ge: null,
  gt: null,
  le: null,
  lt: null,
};

export const ModelIntFilterInputDefaults = {
  between: null,
  eq: null,
  ge: null,
  gt: null,
  le: null,
  lt: null,
  ne: null,
};

export const ModelIntKeyConditionInputDefaults = {
  between: null,
  eq: null,
  ge: null,
  gt: null,
  le: null,
  lt: null,
};

export const ModelNotificationFilterInputDefaults = {
  data: null,
  entityId: null,
  id: null,
  initiatedBy: null,
  notificationAction: null,
  notificationId: null,
  notificationStatus: null,
  notificationType: null,
  read: null,
  ttl: null,
};

export const ModelOrganizationFilterInputDefaults = {
  auth0Id: null,
  avatar: null,
  description: null,
  name: null,
};

export const ModelProjectFilterInputDefaults = {
  allowGenAI: null,
  archived: null,
  avatar: null,
  avoid: null,
  color: null,
  competitorsUrls: null,
  content: null,
  country: null,
  createdAt: null,
  createdBy: null,
  custom: null,
  description: null,
  directReaderAddressing: null,
  domain: null,
  dueDate: null,
  firstPersonPerspective: null,
  gscConnected: null,
  gscConnectionId: null,
  id: null,
  imageType: null,
  includedInArticleBrief: null,
  inputTopics: null,
  linkedinConnected: null,
  linkedinConnectionId: null,
  linkedinOrgId: null,
  locale: null,
  moodAndTone: null,
  name: null,
  neuraverseId: null,
  organizationId: null,
  orientation: null,
  planedArticles: null,
  planedArticlesPerMonth: null,
  projectId: null,
  selected: null,
  startDate: null,
  style: null,
  topicInspirations: null,
  ttl: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  wpConnected: null,
  wpConnectionId: null,
};

export const ModelStringFilterInputDefaults = {
  beginsWith: null,
  between: null,
  contains: null,
  eq: null,
  ge: null,
  gt: null,
  le: null,
  lt: null,
  ne: null,
  notContains: null,
};

export const ModelStringKeyConditionInputDefaults = {
  beginsWith: null,
  between: null,
  eq: null,
  ge: null,
  gt: null,
  le: null,
  lt: null,
};

export const ModelUiViewFilterInputDefaults = {
  calendarVisibility: null,
  companyPriority: null,
  entityId: null,
  expanded: null,
  filtering: null,
  index: null,
  name: null,
  priority: null,
  searchTerm: null,
  showWinnersLosers: null,
  sorting: null,
  tab: null,
  tableId: null,
  visibility: null,
};

export const ModelUserFilterInputDefaults = {
  avatar: null,
  color: null,
  email: null,
  firstName: null,
  lastName: null,
  lastReadNewsFeedPubDate: null,
  locale: null,
  nickname: null,
};

export const MutationDefaults = {
  bulkExport: '',
  bulkWordPressExport: '',
  createAnalyticsLog: false,
  createInvitations: null,
  createLogin: null,
  createOneClickAnalysis: null,
  createOrganization: null,
  createTopicInspirations: null,
  createUiView: null,
  deleteArticleHeadline: null,
  deleteArticleHeadlines: [],
  deleteArticleKeyword: null,
  deleteArticleQuestion: null,
  deleteInvitation: null,
  deleteOrganization: null,
  deleteUiView: null,
  grantUserRoles: null,
  linkNeuraverseToProject: false,
  onApiConnectionCreateMutation: null,
  onApiConnectionDeleteMutation: null,
  onApiConnectionUpdateMutation: null,
  onArticleBriefCreateMutation: null,
  onArticleBriefDeleteMutation: null,
  onArticleBriefUpdateMutation: null,
  onArticleCreateMutation: null,
  onArticleDeleteMutation: null,
  onArticleUpdateMutation: null,
  onNVePriorityUpdateMutation: null,
  onNVeTopicSavedUpdateMutation: null,
  onNotificationCreateMutation: null,
  onNotificationDeleteMutation: null,
  onNotificationUpdateMutation: null,
  onOrganizationCreateMutation: null,
  onOrganizationDeleteMutation: null,
  onOrganizationUpdateMutation: null,
  onProjectCreateMutation: null,
  onProjectDeleteMutation: null,
  onProjectUpdateMutation: null,
  onUiViewCreateMutation: null,
  onUiViewDeleteMutation: null,
  onUiViewUpdateMutation: null,
  onUserCreateMutation: null,
  onUserDeleteMutation: null,
  onUserUpdateMutation: null,
  readNotifications: [],
  revokeUserRoles: null,
  triggerRScore: null,
  updateArticleHeadlines: [],
  updateArticleKeywords: [],
  updateArticleQuestions: [],
  updateOrganization: null,
  updateProjectBriefDocuments: [],
  updateUiView: null,
  updateUser: null,
  updateUserArticleData: null,
  updateUserProjectData: null,
  upsertArticleHeadlines: [],
  upsertArticleKeywords: [],
  upsertArticleQuestions: [],
  upsertWordPressConnection: null,
  wpExport: '',
};

export const NVeCompanyDefaults = {
  articles: 0,
  articlesInTop20: 0,
  avgRanking: 0,
  id: '',
  name: '',
  priority: null,
  searchTerms: 0,
  traffic: 0,
  updateToken: null,
};

export const NVeFilterDataDefaults = {
  id: '',
};

export const NVeMetadataDefaults = {
  competitors: [],
  createdAt: '',
  customer: '',
  id: '',
  updatedAt: '',
};

export const NVePriorityUpdateSubscriptionDefaults = {
  id: null,
  pk1: null,
  priority: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  userId: null,
};

export const NVePriorityUpdateSubscriptionInputDefaults = {
  id: null,
  pk1: null,
  priority: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  userId: null,
};

export const NVeTopicDefaults = {
  articlesCount: 0,
  childTopicCount: 0,
  companyData: null,
  competitorTopic: false,
  depth: 0,
  domainData: null,
  fullName: '',
  id: '',
  isSaved: null,
  myTopic: false,
  priority: null,
  relevance: 0,
  searchTermsCount: 0,
  searchVolume: 0,
  topKeywords: [],
  topicGap: false,
  updateToken: null,
};

export const NVeTopicArticleDefaults = {
  articleMetadata: null,
  direction: null,
  fullName: '',
  id: '',
  priority: false,
  rScore: null,
  updateToken: null,
  webArticle: null,
};

export const NVeTopicArticleListDefaults = {
  items: [],
  lastEvaluatedKey: null,
};

export const NVeTopicCompanyDataDefaults = {
  articlesCount: 0,
  averageRanking: 0,
  name: '',
  searchTermsCount: 0,
  top20ArticlesCount: 0,
  traffic: 0,
};

export const NVeTopicDomainDataDefaults = {
  articlesCount: 0,
  averageRanking: 0,
  hostname: '',
  searchTermsCount: 0,
  top20ArticlesCount: 0,
  traffic: 0,
};

export const NVeTopicSavedUpdateDefaults = {
  id: '',
  isSaved: false,
  updateToken: null,
  updatedAt: null,
};

export const NVeTopicSavedUpdateSubscriptionDefaults = {
  id: null,
  isSaved: null,
  pk1: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  userId: null,
};

export const NVeTopicSavedUpdateSubscriptionInputDefaults = {
  id: null,
  isSaved: null,
  pk1: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  userId: null,
};

export const NVeTopicSearchTermDefaults = {
  content: '',
  ranking: 0,
  searchVolume: 0,
};

export const NVeTopicWebArticleDefaults = {
  preScore: null,
  ranking: null,
  title: '',
  traffic: null,
  url: '',
};

export const NVeTopicsExportDefaults = {
  key: '',
};

export const NVeTopicsExportInputDefaults = {
  id: '',
};

export const NVeTopicsImportInputDefaults = {
  id: '',
};

export const NotificationDefaults = {
  createdAt: null,
  data: null,
  entityId: null,
  id: null,
  initiatedBy: null,
  notificationAction: null,
  notificationId: null,
  notificationStatus: null,
  notificationType: null,
  pk1: null,
  read: null,
  sk1: null,
  ttl: null,
  updatedAt: null,
};

export const NotificationDeleteDefaults = {
  id: '',
};

export const NotificationSettingsDefaults = {};

export const NotificationSettingsInputDefaults = {};

export const NotificationSubscriptionDefaults = {
  createdAt: null,
  data: null,
  entityId: null,
  filter: null,
  id: null,
  initiatedBy: null,
  notificationAction: null,
  notificationId: null,
  notificationStatus: null,
  notificationType: null,
  pk1: null,
  read: null,
  sk1: null,
  ttl: null,
  updatedAt: null,
  userId: null,
};

export const NotificationSubscriptionDeleteDefaults = {
  filter: null,
  pk1: null,
  sk1: null,
  userId: null,
};

export const NotificationSubscriptionInputDefaults = {
  createdAt: null,
  data: null,
  entityId: null,
  filter: null,
  id: null,
  initiatedBy: null,
  notificationAction: null,
  notificationId: null,
  notificationStatus: null,
  notificationType: null,
  pk1: null,
  read: null,
  sk1: null,
  ttl: null,
  updatedAt: null,
  userId: null,
};

export const OcaFailedReportDefaults = {
  error: '',
  url: 0,
};

export const OcaRecommendationDefaults = {
  id: '',
  urls: [],
};

export const OcaRecommendationsDefaults = {
  critical: [],
  low: [],
  medium: [],
};

export const OneClickAnalysisDefaults = {
  createdAt: toAWSDateTime(new Date()),
  error: null,
  finishedAt: null,
  report: null,
  updatedAt: null,
};

export const OneClickAnalysisReportDefaults = {
  coreWebVitals: null,
  failedReports: null,
  score: null,
  urls: [],
};

export const OrganizationDefaults = {
  auth0Id: null,
  avatar: null,
  createdAt: null,
  description: null,
  id: null,
  name: null,
  pk1: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
};

export const OrganizationDeleteDefaults = {
  id: '',
};

export const OrganizationItemsDefaults = {
  items: [],
};

export const OrganizationSubscriptionDefaults = {
  auth0Id: null,
  avatar: null,
  createdAt: null,
  description: null,
  filter: null,
  id: null,
  name: null,
  pk1: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  userId: null,
};

export const OrganizationSubscriptionDeleteDefaults = {
  filter: null,
  id: null,
  pk1: null,
  userId: null,
};

export const OrganizationSubscriptionInputDefaults = {
  auth0Id: null,
  avatar: null,
  createdAt: null,
  description: null,
  filter: null,
  id: null,
  name: null,
  pk1: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  userId: null,
};

export const OutlineByGptInputDefaults = {
  articleId: '',
  updateToken: null,
};

export const OutlineHtmlExportDefaults = {
  key: null,
  name: null,
};

export const OutlineHtmlExportInputDefaults = {
  articleId: '',
};

export const PlatformVersionDefaults = {
  BE: '',
  FE: '',
  ML: '',
};

export const ProjectDefaults = {
  allowGenAI: false,
  archived: null,
  avatar: null,
  avoid: null,
  color: null,
  competitorsUrls: null,
  content: '',
  country: null,
  createdAt: '',
  createdBy: null,
  custom: null,
  customFields: null,
  description: null,
  domain: null,
  dueDate: null,
  gscConnected: null,
  gscConnectionId: null,
  id: '',
  includedInArticleBrief: null,
  inputTopics: null,
  linkedinConnected: null,
  linkedinConnectionId: null,
  linkedinOrgId: null,
  name: '',
  neuraverseId: null,
  organizationId: '',
  pk1: '',
  planedArticles: null,
  planedArticlesPerMonth: null,
  projectId: '',
  selected: null,
  sk1: '',
  startDate: null,
  topicInspirations: null,
  ttl: null,
  updateToken: null,
  updatedAt: '',
  updatedBy: null,
  wpConnected: null,
  wpConnectionId: null,
};

export const ProjectBriefDefaults = {
  audienceExpertises: [],
  audiencePersonas: [],
  companyKnowledges: [],
  toneAndVoices: [],
};

export const ProjectBriefDocumentDefaults = {
  content: '',
  createdAt: '',
  createdBy: null,
  id: '',
  name: '',
  pk1: '',
  projectId: '',
  selected: null,
  sk1: '',
  updateToken: null,
  updatedAt: '',
  updatedBy: null,
};

export const ProjectBriefDocumentDeleteDefaults = {
  id: '',
};

export const ProjectBriefMetadataDefaults = {
  avoid: null,
  competitorsUrls: null,
  createdAt: '',
  createdBy: null,
  customFields: null,
  dueDate: null,
  id: '',
  includedInArticleBrief: null,
  inputTopics: null,
  pk1: '',
  planedArticles: null,
  planedArticlesPerMonth: null,
  sk1: '',
  startDate: null,
  updateToken: null,
  updatedAt: '',
  updatedBy: null,
};

export const ProjectDeleteDefaults = {
  id: '',
};

export const ProjectImageGuidelinesDefaults = {
  allowGenAI: false,
  createdAt: '',
  createdBy: null,
  customFields: null,
  id: '',
  pk1: '',
  sk1: '',
  updateToken: null,
  updatedAt: '',
  updatedBy: null,
};

export const ProjectInviteDefaults = {
  projectId: '',
  role: '',
};

export const ProjectLabelDefaults = {
  color: '',
  createdAt: '',
  createdBy: null,
  custom: null,
  id: '',
  name: '',
  pk1: '',
  projectId: '',
  sk1: '',
  updateToken: null,
  updatedAt: '',
  updatedBy: null,
};

export const ProjectLabelDeleteDefaults = {
  id: '',
};

export const ProjectMemberDefaults = {
  aclRoles: [],
  id: null,
};

export const ProjectMetadataDefaults = {
  archived: null,
  articleStatistics: null,
  avatar: null,
  brief: null,
  color: null,
  country: null,
  createdAt: '',
  createdBy: null,
  description: null,
  domain: null,
  gscConnected: null,
  gscConnectionId: null,
  id: '',
  labels: null,
  linkedinConnected: null,
  linkedinConnectionId: null,
  linkedinOrgId: null,
  name: null,
  neuraverseId: null,
  neuraverseVersion: null,
  organizationId: '',
  pk1: '',
  sk1: '',
  topicInspirations: null,
  ttl: null,
  updateToken: null,
  updatedAt: '',
  updatedBy: null,
  userProject: null,
  wpConnected: null,
  wpConnectionId: null,
};

export const ProjectNotificationSettingsDefaults = {
  email: false,
  inapp: false,
};

export const ProjectNotificationSettingsInputDefaults = {
  email: false,
  inapp: false,
};

export const ProjectSubscriptionDefaults = {
  allowGenAI: null,
  archived: null,
  avatar: null,
  avoid: null,
  color: null,
  competitorsUrls: null,
  content: null,
  country: null,
  createdAt: null,
  createdBy: null,
  custom: null,
  customFields: null,
  description: null,
  directReaderAddressing: null,
  domain: null,
  dueDate: null,
  filter: null,
  firstPersonPerspective: null,
  gscConnected: null,
  gscConnectionId: null,
  id: null,
  imageType: null,
  includedInArticleBrief: null,
  inputTopics: null,
  linkedinConnected: null,
  linkedinConnectionId: null,
  linkedinOrgId: null,
  locale: null,
  moodAndTone: null,
  name: null,
  neuraverseId: null,
  organizationId: null,
  orientation: null,
  pk1: null,
  planedArticles: null,
  planedArticlesPerMonth: null,
  projectId: null,
  selected: null,
  sk1: null,
  startDate: null,
  style: null,
  topicInspirations: null,
  ttl: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  userId: null,
  wpConnected: null,
  wpConnectionId: null,
};

export const ProjectSubscriptionDeleteDefaults = {
  id: null,
  pk1: null,
  type: null,
  userId: null,
};

export const ProjectSubscriptionInputDefaults = {
  allowGenAI: null,
  archived: null,
  avatar: null,
  avoid: null,
  color: null,
  competitorsUrls: null,
  content: null,
  country: null,
  createdAt: null,
  createdBy: null,
  custom: null,
  customFields: null,
  description: null,
  directReaderAddressing: null,
  domain: null,
  dueDate: null,
  filter: null,
  firstPersonPerspective: null,
  gscConnected: null,
  gscConnectionId: null,
  id: null,
  imageType: null,
  includedInArticleBrief: null,
  inputTopics: null,
  linkedinConnected: null,
  linkedinConnectionId: null,
  linkedinOrgId: null,
  locale: null,
  moodAndTone: null,
  name: null,
  neuraverseId: null,
  organizationId: null,
  orientation: null,
  pk1: null,
  planedArticles: null,
  planedArticlesPerMonth: null,
  projectId: null,
  selected: null,
  sk1: null,
  startDate: null,
  style: null,
  topicInspirations: null,
  ttl: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  userId: null,
  wpConnected: null,
  wpConnectionId: null,
};

export const PublishArticlePostInputDefaults = {
  id: '',
};

export const PublishArticlePostOutputDefaults = {
  code: null,
  id: '',
  statusCode: 0,
  url: null,
};

export const QueryDefaults = {
  compareArticleVersions: null,
  dummy: null,
  getApiConnection: null,
  getBriefExportAsDocx: '',
  getBriefExportAsPdf: '',
  getContentCategories: null,
  getGSCArticleAnalytics: null,
  getGSCConsentScreenUri: null,
  getGSCProjectAnalytics: null,
  getGlobalPermissionsTree: null,
  getGlobalRoleDefinitions: null,
  getGoogleTopRankings: null,
  getHtmlExport: null,
  getIsProjectMonitored: false,
  getLastProductUpdate: null,
  getLinkedinConsentScreenUri: null,
  getNotification: null,
  getOneClickAnalysis: null,
  getOrganization: null,
  getOutlineHtmlExport: null,
  getProjectBriefDocumentUsageCount: 0,
  getProjectLabelUsageCount: 0,
  getSimilarPhrasesByGPT: null,
  getSimilarPhrasesByNeuraverse: null,
  getSimilarQuestions: null,
  getUiView: null,
  getUploadUrl: null,
  getUser: null,
  getUserRoleAssignments: null,
  getVersion: null,
  getWordPressConnection: null,
  listApiConnections: null,
  listArticleKeywords: [],
  listArticleQuestions: [],
  listBrokenLinksInArticle: null,
  listGSCArticlesByKeyword: null,
  listGSCArticlesByProject: null,
  listGSCDomains: null,
  listGSCKeywordsByArticle: null,
  listGSCKeywordsByProject: null,
  listGSCTopChangingArticles: null,
  listInvitations: null,
  listLinkedinOrganizations: [],
  listNVeCompanies: [],
  listNVeTopicSearchTerms: [],
  listNVeTopicTop20Articles: [],
  listNVeTopics: [],
  listNVeWebArticles: [],
  listNotifications: null,
  listOrganizations: null,
  listProjectMembers: [],
  listUiViews: null,
  listUserProjects: null,
  listUsers: null,
  listUsersWithProjects: null,
  listWordPressCategories: null,
};

export const ReadNotificationsInputDefaults = {
  notificationIds: [],
};

export const RestoreVersionInputDefaults = {
  articleId: '',
};

export const SelectedProjectBriefDocumentsDefaults = {
  audienceExpertise: null,
  audiencePersona: null,
  companyKnowledges: null,
  toneAndVoice: null,
};

export const ShortenOrDivideSentenceByGptInputDefaults = {
  articleId: '',
  keywords: [],
  notificationId: null,
  sentence: '',
};

export const SimilarPhraseElementDefaults = {
  phrase: '',
  required: null,
  volumes: null,
};

export const SimilarPhraseVarietyDefaults = {
  phrases: [],
};

export const SimilarPhrasesDefaults = {
  items: null,
  varieties: null,
};

export const SimilarPhrasesByGptInputDefaults = {
  contentCategory: null,
  contentSubCategory: null,
  limit: 0,
  notificationId: null,
  searchTerms: [],
  timeFrame: 0,
};

export const SimilarPhrasesByNeuraverseInputDefaults = {
  articleDirection: null,
  articleLengthSuggestion: null,
  limit: null,
  notificationId: '',
  organizationId: null,
  searchTerms: [],
  topicId: '',
  topicNames: [],
  userId: null,
};

export const SimilarQuestionElementDefaults = {
  question: '',
};

export const SimilarQuestionsDefaults = {
  items: null,
};

export const SimilarQuestionsInputDefaults = {
  notificationId: null,
  query: '',
};

export const SubscriptionDefaults = {
  onApiConnectionCreate: null,
  onApiConnectionDelete: null,
  onApiConnectionDeleteById: null,
  onApiConnectionUpdate: null,
  onApiConnectionUpdateById: null,
  onArticleBriefCreate: null,
  onArticleBriefDelete: null,
  onArticleBriefDeleteById: null,
  onArticleBriefUpdate: null,
  onArticleBriefUpdateById: null,
  onArticleCreate: null,
  onArticleDelete: null,
  onArticleDeleteById: null,
  onArticleUpdate: null,
  onArticleUpdateById: null,
  onNVePriorityUpdate: null,
  onNVeTopicSavedUpdate: null,
  onNotificationCreate: null,
  onNotificationDelete: null,
  onNotificationDeleteById: null,
  onNotificationUpdate: null,
  onNotificationUpdateById: null,
  onOrganizationCreate: null,
  onOrganizationDelete: null,
  onOrganizationDeleteById: null,
  onOrganizationUpdate: null,
  onOrganizationUpdateById: null,
  onProjectCreate: null,
  onProjectDelete: null,
  onProjectDeleteById: null,
  onProjectUpdate: null,
  onProjectUpdateById: null,
  onUiViewCreate: null,
  onUiViewDelete: null,
  onUiViewDeleteById: null,
  onUiViewUpdate: null,
  onUiViewUpdateById: null,
  onUserCreate: null,
  onUserDelete: null,
  onUserDeleteById: null,
  onUserRoleAssignments: null,
  onUserUpdate: null,
  onUserUpdateById: null,
};

export const UiViewDefaults = {
  calendarVisibility: null,
  companyPriority: null,
  createdAt: null,
  createdBy: null,
  entityId: null,
  expanded: null,
  filtering: null,
  id: null,
  index: null,
  name: null,
  pk1: null,
  priority: null,
  searchTerm: null,
  showWinnersLosers: null,
  sorting: null,
  tab: null,
  tableId: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  visibility: null,
};

export const UiViewDeleteDefaults = {
  id: '',
};

export const UiViewSubscriptionDefaults = {
  calendarVisibility: null,
  companyPriority: null,
  createdAt: null,
  createdBy: null,
  entityId: null,
  expanded: null,
  filter: null,
  filtering: null,
  id: null,
  index: null,
  name: null,
  pk1: null,
  priority: null,
  searchTerm: null,
  showWinnersLosers: null,
  sorting: null,
  tab: null,
  tableId: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  userId: null,
  visibility: null,
};

export const UiViewSubscriptionDeleteDefaults = {
  filter: null,
  id: null,
  pk1: null,
  userId: null,
};

export const UiViewSubscriptionInputDefaults = {
  calendarVisibility: null,
  companyPriority: null,
  createdAt: null,
  createdBy: null,
  entityId: null,
  expanded: null,
  filter: null,
  filtering: null,
  id: null,
  index: null,
  name: null,
  pk1: null,
  priority: null,
  searchTerm: null,
  showWinnersLosers: null,
  sorting: null,
  tab: null,
  tableId: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  userId: null,
  visibility: null,
};

export const UpdateApiConnectionInputDefaults = {
  connectionData: null,
  connectionId: null,
  organizationId: '',
  updateToken: null,
  updatedBy: null,
};

export const UpdateArticleBriefInputDefaults = {
  answered: null,
  articleId: null,
  content: null,
  createdAt: null,
  createdBy: null,
  description: null,
  hidden: null,
  id: '',
  index: null,
  keywordVariations: null,
  keywords: null,
  level: null,
  questions: null,
  relevance: null,
  required: null,
  title: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  volumes: null,
};

export const UpdateArticleCommentInputDefaults = {
  content: null,
  id: '',
  resolved: null,
  updateToken: null,
};

export const UpdateArticleDocumentInputDefaults = {
  content: null,
  id: '',
  updateToken: null,
  updatedAt: null,
  versionCreatorId: null,
  versionDate: null,
  versionId: null,
  versionName: null,
};

export const UpdateArticleGuidelinesInputDefaults = {
  guidelines: null,
  id: '',
  updateToken: null,
  versionCreatorId: null,
  versionDate: null,
  versionId: null,
  versionName: null,
};

export const UpdateArticleHeadlineInputDefaults = {
  description: null,
  id: '',
  index: null,
  level: null,
  title: null,
};

export const UpdateArticleHeadlinesInputDefaults = {
  articleId: '',
  headlines: [],
  updateToken: null,
};

export const UpdateArticleInputDefaults = {
  advices: null,
  articleId: null,
  articlePostType: null,
  assigneeId: null,
  attachments: null,
  audienceExpertiseId: null,
  audiencePersonaId: null,
  avoid: null,
  commentId: null,
  companyKnowledgeIds: null,
  competitorsUrls: null,
  completedDate: null,
  content: null,
  createdAt: null,
  createdBy: null,
  customFields: null,
  description: null,
  directReaderAddressing: null,
  doneAdvices: null,
  dueDate: null,
  exploreInputs: null,
  firstPersonPerspective: null,
  focusKeyword: null,
  guidelines: null,
  hasTopRankings: null,
  id: '',
  imageGuidelines: null,
  inputTopics: null,
  labelIds: null,
  name: null,
  neuraverseTopicData: null,
  pk1: null,
  preferences: null,
  priority: null,
  projectId: null,
  publicationDate: null,
  readingResources: null,
  requestOrigin: null,
  resolved: null,
  rscore: null,
  sk1: null,
  sk2: null,
  state: null,
  title: null,
  toneAndVoiceId: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  urlIndexedAt: null,
  urlPath: null,
  urlStatus: null,
  versionCreatorId: null,
  versionDate: null,
  versionId: null,
  versionName: null,
  wordCountPerArticle: null,
};

export const UpdateArticleKeywordInputDefaults = {
  content: null,
  hidden: null,
  id: '',
  required: null,
};

export const UpdateArticleKeywordsInputDefaults = {
  articleId: '',
  keywords: [],
  updateToken: null,
};

export const UpdateArticleMetadataInputDefaults = {
  assigneeId: null,
  audienceExpertiseId: null,
  audiencePersonaId: null,
  avoid: null,
  companyKnowledgeIds: null,
  competitorsUrls: null,
  completedDate: null,
  customFields: null,
  description: null,
  directReaderAddressing: null,
  dueDate: null,
  exploreInputs: null,
  firstPersonPerspective: null,
  focusKeyword: null,
  hasTopRankings: null,
  id: '',
  imageGuidelines: null,
  inputTopics: null,
  name: null,
  neuraverseTopicData: null,
  projectId: null,
  publicationDate: null,
  readingResources: null,
  state: null,
  title: null,
  toneAndVoiceId: null,
  updateToken: null,
  urlPath: null,
  versionCreatorId: null,
  versionDate: null,
  versionId: null,
  versionName: null,
  wordCountPerArticle: null,
};

export const UpdateArticlePostInputDefaults = {
  attachments: null,
  content: null,
  id: '',
  name: null,
  updateToken: null,
};

export const UpdateArticleQuestionInputDefaults = {
  answered: null,
  content: null,
  hidden: null,
  id: '',
};

export const UpdateArticleQuestionsInputDefaults = {
  articleId: '',
  questions: [],
  updateToken: null,
};

export const UpdateArticleRScoreInputDefaults = {
  doneAdvices: null,
  id: '',
  rscore: null,
  updateToken: null,
  versionCreatorId: null,
  versionDate: null,
  versionId: null,
  versionName: null,
};

export const UpdateArticleRootDataInputDefaults = {
  id: '',
  labelIds: null,
  priority: null,
  updateToken: null,
  urlIndexedAt: null,
};

export const UpdateAuth0UserRoleInputDefaults = {
  userId: '',
};

export const UpdateNVePriorityInputDefaults = {
  id: '',
  priority: false,
  updateToken: null,
};

export const UpdateNVeTopicArticlePriorityInputDefaults = {
  id: '',
  priority: false,
  updateToken: null,
};

export const UpdateNVeTopicSavedInputDefaults = {
  id: '',
  isSaved: false,
  updateToken: null,
};

export const UpdateNotificationInputDefaults = {
  createdAt: null,
  data: null,
  entityId: null,
  id: '',
  initiatedBy: null,
  notificationAction: null,
  notificationId: null,
  notificationStatus: null,
  notificationType: null,
  read: null,
  ttl: null,
  updatedAt: null,
};

export const UpdateOrganizationInputDefaults = {
  avatar: null,
  createdAt: null,
  description: null,
  id: '',
  name: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
};

export const UpdateProjectBriefDocumentInputDefaults = {
  content: null,
  id: '',
  name: null,
  projectId: null,
  selected: null,
  updateToken: null,
};

export const UpdateProjectBriefDocumentsInputDefaults = {
  documents: [],
  projectId: '',
  updateToken: null,
};

export const UpdateProjectBriefMetadataInputDefaults = {
  avoid: null,
  competitorsUrls: null,
  createdBy: null,
  customFields: null,
  directReaderAddressing: null,
  dueDate: null,
  firstPersonPerspective: null,
  id: '',
  includedInArticleBrief: null,
  inputTopics: null,
  planedArticles: null,
  planedArticlesPerMonth: null,
  startDate: null,
  type: null,
  updateToken: null,
  updatedBy: null,
};

export const UpdateProjectImageGuidelinesInputDefaults = {
  allowGenAI: null,
  createdBy: null,
  customFields: null,
  id: '',
  imageType: null,
  moodAndTone: null,
  orientation: null,
  style: null,
  type: null,
  updateToken: null,
  updatedBy: null,
};

export const UpdateProjectInputDefaults = {
  allowGenAI: null,
  archived: null,
  avatar: null,
  avoid: null,
  color: null,
  competitorsUrls: null,
  content: null,
  country: null,
  createdAt: null,
  createdBy: null,
  custom: null,
  customFields: null,
  description: null,
  directReaderAddressing: null,
  domain: null,
  dueDate: null,
  firstPersonPerspective: null,
  gscConnected: null,
  gscConnectionId: null,
  id: '',
  imageType: null,
  includedInArticleBrief: null,
  inputTopics: null,
  linkedinConnected: null,
  linkedinConnectionId: null,
  linkedinOrgId: null,
  locale: null,
  moodAndTone: null,
  name: null,
  neuraverseId: null,
  organizationId: null,
  orientation: null,
  pk1: null,
  planedArticles: null,
  planedArticlesPerMonth: null,
  projectId: null,
  selected: null,
  sk1: null,
  startDate: null,
  style: null,
  topicInspirations: null,
  ttl: null,
  type: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  wpConnected: null,
  wpConnectionId: null,
};

export const UpdateProjectLabelInputDefaults = {
  color: null,
  id: '',
  name: null,
  projectId: null,
  updateToken: null,
};

export const UpdateProjectMetadataInputDefaults = {
  archived: null,
  avatar: null,
  color: null,
  country: null,
  description: null,
  domain: null,
  gscConnected: null,
  gscConnectionId: null,
  id: '',
  linkedinConnected: null,
  linkedinConnectionId: null,
  linkedinOrgId: null,
  name: null,
  neuraverseId: null,
  organizationId: null,
  topicInspirations: null,
  ttl: null,
  updateToken: null,
  wpConnected: null,
  wpConnectionId: null,
};

export const UpdateUiViewInputDefaults = {
  calendarVisibility: null,
  companyPriority: null,
  expanded: null,
  filtering: null,
  id: '',
  index: null,
  name: null,
  priority: null,
  searchTerm: null,
  showWinnersLosers: null,
  sorting: null,
  tab: null,
  tableId: null,
  updateToken: null,
  visibility: null,
};

export const UpdateUserArticleDataInputDefaults = {
  articleId: '',
  updateToken: null,
  value: false,
};

export const UpdateUserInputDefaults = {
  avatar: null,
  color: null,
  email: null,
  firstName: null,
  lastName: null,
  lastReadNewsFeedPubDate: null,
  locale: null,
  nickname: null,
  notificationSettings: null,
  updateToken: null,
  userOrgData: null,
};

export const UpdateUserOrgDataInputDefaults = {
  daysBeforeDueDateWarning: null,
  lastAccessedProjectId: null,
  updateToken: null,
};

export const UpdateUserProjectDataInputDefaults = {
  hidden: false,
  projectId: '',
  updateToken: null,
};

export const UploadUrlOutputDefaults = {
  key: '',
  uploadURL: '',
};

export const UpsertArticleHeadlineInputDefaults = {
  articleId: '',
  description: '',
  id: '',
  index: 0,
  level: 0,
  title: '',
  updateToken: null,
};

export const UpsertArticleHeadlinesInputDefaults = {
  articleId: '',
  headlines: [],
  updateToken: null,
};

export const UpsertArticleKeywordsInputDefaults = {
  articleId: '',
  keywords: [],
  updateToken: null,
};

export const UpsertArticleQuestionsInputDefaults = {
  articleId: '',
  questions: [],
  updateToken: null,
};

export const UpsertWordpressConnectionInputDefaults = {
  applicationPassword: '',
  endpoint: '',
  projectId: '',
  username: '',
};

export const UpsertWordpressConnectionResponseDefaults = {
  errors: null,
};

export const UserDefaults = {
  avatar: null,
  color: null,
  createdAt: null,
  email: null,
  firstName: null,
  id: null,
  lastName: null,
  lastReadNewsFeedPubDate: null,
  locale: null,
  nickname: null,
  notificationSettings: null,
  pk1: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  userOrgData: null,
};

export const UserArticleDataDefaults = {
  id: null,
  preferences: null,
};

export const UserDeleteDefaults = {
  id: '',
};

export const UserItemsDefaults = {
  items: [],
};

export const UserOrgDataDefaults = {
  daysBeforeDueDateWarning: null,
  lastAccessedProjectId: null,
  organizationId: '',
  updateToken: null,
};

export const UserOrgDataInputDefaults = {
  daysBeforeDueDateWarning: null,
  lastAccessedProjectId: null,
  organizationId: '',
  updateToken: null,
};

export const UserProjectDataDefaults = {
  hiddenTiles: null,
  id: null,
};

export const UserProjectWithRoleDefaults = {
  roleId: null,
};

export const UserProjectsWithRolesDefaults = {
  projects: [],
};

export const UserRoleAssignmentInputDefaults = {
  entityId: '',
  roleId: '',
};

export const UserRoleInputDefaults = {
  assignments: [],
  userId: '',
};

export const UserSubscriptionDefaults = {
  avatar: null,
  color: null,
  createdAt: null,
  email: null,
  firstName: null,
  id: null,
  lastName: null,
  lastReadNewsFeedPubDate: null,
  locale: null,
  nickname: null,
  notificationSettings: null,
  organizationId: null,
  pk1: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  userId: null,
  userOrgData: null,
};

export const UserSubscriptionDeleteDefaults = {
  id: null,
  organizationId: null,
  pk1: null,
  userId: null,
};

export const UserSubscriptionInputDefaults = {
  avatar: null,
  color: null,
  createdAt: null,
  email: null,
  firstName: null,
  id: null,
  lastName: null,
  lastReadNewsFeedPubDate: null,
  locale: null,
  nickname: null,
  notificationSettings: null,
  organizationId: null,
  pk1: null,
  updateToken: null,
  updatedAt: null,
  updatedBy: null,
  userId: null,
  userOrgData: null,
};

export const UserWithProjectsDefaults = {
  id: null,
  projectsCount: 0,
};

export const VolumeDefaults = {
  amount: 0,
  month: 0,
  year: 0,
};

export const VolumeElementDefaults = {
  amount: 0,
  date: null,
  day: 0,
  month: 0,
  year: 0,
};

export const VolumeInputDefaults = {
  amount: 0,
  month: 0,
  year: 0,
};

export const WordPressConnectionCredentialsDefaults = {
  applicationPassword: '',
  endpoint: '',
  username: '',
};

export const WordPressExportInputDefaults = {
  articleId: '',
  categoryId: null,
  exportId: null,
};

export const WordpressCategoryDefaults = {
  id: '',
  name: '',
};

export const WordpressConnectionDataDefaults = {
  endpoint: '',
  username: '',
};

export const WordpressConnectionErrorDefaults = {
  code: '',
  data: null,
  message: null,
  tag: null,
};

export const WordpressConnectionErrorDataDefaults = {
  status: 0,
};

export const ListNVeTopicSearchTermsInputDefaults = {
  id: '',
  name: '',
};
