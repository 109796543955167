const prependZero = (n: number) => `0${n}`.slice(-2);

// Formats a date to an AWSDateTime 2022-03-31T00:00:00.000Z
export const toAWSDate = (input: Date) =>
  `${input.getFullYear()}-${prependZero(input.getMonth() + 1)}-${prependZero(
    input.getDate()
  )}T00:00:00.000Z`;

export const toAWSDateTime = (input: Date) => input.toISOString();

export const fromAWSDateTime = (input: string) => {
  const parsed = Date.parse(input);
  if (!isNaN(parsed)) {
    return new Date(parsed);
  }

  return null;
};

// Formats an AWSDateTime to a date
export const fromAWSDate = (input: string) => {
  const match = input.match(/([0-9]{4,4})-([0-9]{2,2})-([0-9]{2,2})/i);
  if (match) {
    return new Date(
      parseInt(match[1], 10),
      parseInt(match[2], 10) - 1,
      parseInt(match[3], 10)
    );
  }
  return null;
};
