import { Reference } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';
import { TypeMapping } from './mappings';

export type SubscriptionItem = {
  type?: string;
  __typename?: string;
  pk1: string;
  id?: string;
  sk1?: string;
};

export const isSubscriptionItem = (item: any): item is SubscriptionItem =>
  ((item && 'type' in item) || (item && '__typename' in item)) && 'pk1' in item;

export type IdItem = {
  id: string;
  __typename: string;
};

export const isIdItem = (item: any): item is IdItem =>
  item && 'id' in item && '__typename' in item;

export const isReference = (item: any): item is Reference =>
  item && '__ref' in item;

// Convert the id or sk1 and type from a database table to an apollo cache id
export const getCacheId = (
  dataItem: Pick<SubscriptionItem, 'id' | 'sk1'>,
  typeMapping: TypeMapping
) => {
  const typePart = typeMapping.typename;
  const idPart = dataItem.id ?? dataItem.sk1?.split('#').slice(1).join('#');
  return `${typePart}:${idPart}`;
};

export const makeItemsUnique = <T extends any[]>(array: T): T =>
  uniqBy(array, (item) => {
    if (isIdItem(item)) {
      return `${item.__typename}:${item.id}`;
    }

    if (isReference(item)) {
      return item.__ref;
    }

    return item;
  }) as T;

export const handleSubscriptionMessage = (
  data: Record<string, any> | null | undefined,
  handler: (item: SubscriptionItem) => void
) => {
  if (data) {
    if (isSubscriptionItem(data)) {
      handler(data);
    } else {
      // Iterate over the first level of properties to find the items to handle (e.g. in onArticleBriefCreate)
      for (const key in data) {
        const item = data[key];
        if (isSubscriptionItem(item)) {
          handler(item);
        }
      }
    }
  }
};
