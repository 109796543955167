import { DocumentNode } from 'graphql';
import {
  ArticleCommentDefaults,
  ArticleDocumentDefaults,
  ArticleGuidelinesDefaults,
  ArticleHeadlineDefaults,
  ArticleKeywordDefaults,
  ArticleMetadataDefaults,
  ArticlePostAdvicesDefaults,
  ArticlePostDefaults,
  ArticleQuestionDefaults,
  ArticleRScoreDefaults,
  ArticleRootDataDefaults,
  ProjectImageGuidelinesDefaults,
  NVeCompanyDefaults,
  NVeTopicArticleDefaults,
  NVeTopicDefaults,
  NotificationDefaults,
  OrganizationDefaults,
  ProjectBriefDocumentDefaults,
  ProjectBriefMetadataDefaults,
  ProjectMetadataDefaults,
  UiViewDefaults,
  UserDefaults,
  ProjectLabelDefaults,
} from './defaults';
import {
  ArticleBriefType,
  ArticleCommentFull,
  ArticleDocumentFull,
  ArticleGuidelinesFull,
  ArticleHeadlineFull,
  ArticleKeywordFull,
  ArticleMetadataFull,
  ArticlePostAdvicesFull,
  ArticlePostFull,
  ArticleQuestionFull,
  ArticleRScoreFull,
  ArticleRootDataFull,
  ArticleType,
  ProjectImageGuidelinesFull,
  NVeCompanyFull,
  NVeTopicArticleFull,
  NVeTopicFull,
  NotificationFull,
  OnArticleBriefCreateDocument,
  OnArticleBriefDeleteDocument,
  OnArticleBriefUpdateDocument,
  OnArticleCreateDocument,
  OnArticleDeleteByIdDocument,
  OnArticleDeleteDocument,
  OnArticleUpdateByIdDocument,
  OnArticleUpdateDocument,
  OnNotificationCreateDocument,
  OnNotificationDeleteDocument,
  OnNotificationUpdateDocument,
  OnOrganizationDeleteByIdDocument,
  OnOrganizationUpdateByIdDocument,
  OnProjectCreateDocument,
  OnProjectDeleteByIdDocument,
  OnProjectDeleteDocument,
  OnProjectUpdateByIdDocument,
  OnProjectUpdateDocument,
  OnUiViewCreateDocument,
  OnUiViewDeleteByIdDocument,
  OnUiViewDeleteDocument,
  OnUiViewUpdateByIdDocument,
  OnUiViewUpdateDocument,
  OnUserCreateDocument,
  OnUserDeleteByIdDocument,
  OnUserDeleteDocument,
  OnUserUpdateByIdDocument,
  OnUserUpdateDocument,
  OrganizationFull,
  ProjectBriefDocumentFull,
  ProjectBriefMetadataFull,
  ProjectMetadataFull,
  ProjectType,
  UiViewFull,
  UserFull,
  ProjectLabelFull,
} from './graphql';
import { StrictTypedTypePolicies } from './helpers';

export type TypeMapping = {
  typename: keyof StrictTypedTypePolicies;
  fragment: DocumentNode;
  fragmentName: string;
  defaults: Record<string, any>;
};

export type TableMapping = {
  createSubscriptions: DocumentNode[];
  updateSubscriptions: DocumentNode[];
  deleteSubscriptions: DocumentNode[];
  typeMappings: Map<string, TypeMapping>;
};

export const userTypeMapping: TypeMapping = {
  typename: 'User',
  fragment: UserFull,
  fragmentName: 'UserFull',
  defaults: UserDefaults,
};

export const userTableMapping: TableMapping = {
  createSubscriptions: [OnUserCreateDocument],
  updateSubscriptions: [OnUserUpdateDocument, OnUserUpdateByIdDocument],
  deleteSubscriptions: [OnUserDeleteDocument, OnUserDeleteByIdDocument],
  typeMappings: new Map<string, TypeMapping>([
    ['UserSubscription', userTypeMapping],
    ['UserSubscriptionDelete', userTypeMapping],
    ['User', userTypeMapping],
  ]),
};

export const organizationTypeMapping: TypeMapping = {
  typename: 'Organization',
  fragment: OrganizationFull,
  fragmentName: 'OrganizationFull',
  defaults: OrganizationDefaults,
};

export const organizationTableMapping: TableMapping = {
  createSubscriptions: [],
  updateSubscriptions: [OnOrganizationUpdateByIdDocument],
  deleteSubscriptions: [OnOrganizationDeleteByIdDocument],
  typeMappings: new Map<string, TypeMapping>([
    ['OrganizationSubscription', organizationTypeMapping],
    ['OrganizationSubscriptionDelete', organizationTypeMapping],
    ['Organization', organizationTypeMapping],
  ]),
};

export const projectMetadataTypeMapping: TypeMapping = {
  typename: 'ProjectMetadata',
  fragment: ProjectMetadataFull,
  fragmentName: 'ProjectMetadataFull',
  defaults: ProjectMetadataDefaults,
};

export const projectBriefMetadataTypeMapping: TypeMapping = {
  typename: 'ProjectBriefMetadata',
  fragment: ProjectBriefMetadataFull,
  fragmentName: 'ProjectBriefMetadataFull',
  defaults: ProjectBriefMetadataDefaults,
};

export const projectBriefDocumentTypeMapping: TypeMapping = {
  typename: 'ProjectBriefDocument',
  fragment: ProjectBriefDocumentFull,
  fragmentName: 'ProjectBriefDocumentFull',
  defaults: ProjectBriefDocumentDefaults,
};

export const projectImageGuidelinesTypeMapping: TypeMapping = {
  typename: 'ProjectImageGuidelines',
  fragment: ProjectImageGuidelinesFull,
  fragmentName: 'ProjectImageGuidelinesFull',
  defaults: ProjectImageGuidelinesDefaults,
};

export const projectLabelTypeMapping: TypeMapping = {
  typename: 'ProjectLabel',
  fragment: ProjectLabelFull,
  fragmentName: 'ProjectLabelFull',
  defaults: ProjectLabelDefaults,
};

export const projectTableMapping: TableMapping = {
  createSubscriptions: [OnProjectCreateDocument],
  updateSubscriptions: [OnProjectUpdateDocument, OnProjectUpdateByIdDocument],
  deleteSubscriptions: [OnProjectDeleteDocument, OnProjectDeleteByIdDocument],
  typeMappings: new Map<ProjectType, TypeMapping>([
    [ProjectType.Metadata, projectMetadataTypeMapping],
    [ProjectType.BriefMetadata, projectBriefMetadataTypeMapping],
    [ProjectType.AudienceExpertise, projectBriefDocumentTypeMapping],
    [ProjectType.AudiencePersona, projectBriefDocumentTypeMapping],
    [ProjectType.CompanyKnowledge, projectBriefDocumentTypeMapping],
    [ProjectType.ToneAndVoice, projectBriefDocumentTypeMapping],
    [ProjectType.ImageGuidelines, projectImageGuidelinesTypeMapping],
    [ProjectType.Label, projectLabelTypeMapping],
  ]),
};

export const nveTopicTypeMapping: TypeMapping = {
  typename: 'NVeTopic',
  fragment: NVeTopicFull,
  fragmentName: 'NVeTopicFull',
  defaults: NVeTopicDefaults,
};

export const nveTopicArticleTypeMapping: TypeMapping = {
  typename: 'NVeTopicArticle',
  fragment: NVeTopicArticleFull,
  fragmentName: 'NVeTopicArticleFull',
  defaults: NVeTopicArticleDefaults,
};

export const nveCompanyTypeMapping: TypeMapping = {
  typename: 'NVeCompany',
  fragment: NVeCompanyFull,
  fragmentName: 'NVeCompanyFull',
  defaults: NVeCompanyDefaults,
};

export const uiViewTypeMapping: TypeMapping = {
  typename: 'UiView',
  fragment: UiViewFull,
  fragmentName: 'UiViewFull',
  defaults: UiViewDefaults,
};

export const uiViewTableMapping: TableMapping = {
  createSubscriptions: [OnUiViewCreateDocument],
  updateSubscriptions: [OnUiViewUpdateDocument, OnUiViewUpdateByIdDocument],
  deleteSubscriptions: [OnUiViewDeleteDocument, OnUiViewDeleteByIdDocument],
  typeMappings: new Map<string, TypeMapping>([
    ['UiViewSubscription', uiViewTypeMapping],
    ['UiViewSubscriptionDelete', uiViewTypeMapping],
    ['UiView', uiViewTypeMapping],
  ]),
};

export const notificationTypeMapping: TypeMapping = {
  typename: 'Notification',
  fragment: NotificationFull,
  fragmentName: 'NotificationFull',
  defaults: NotificationDefaults,
};

export const notificationTableMapping: TableMapping = {
  createSubscriptions: [OnNotificationCreateDocument],
  updateSubscriptions: [OnNotificationUpdateDocument],
  deleteSubscriptions: [OnNotificationDeleteDocument],
  typeMappings: new Map<string, TypeMapping>([
    ['NotificationSubscription', notificationTypeMapping],
    ['NotificationSubscriptionDelete', notificationTypeMapping],
    ['Notification', notificationTypeMapping],
  ]),
};

export const articleTableMapping: TableMapping = {
  createSubscriptions: [OnArticleCreateDocument],
  updateSubscriptions: [OnArticleUpdateDocument, OnArticleUpdateByIdDocument],
  deleteSubscriptions: [OnArticleDeleteDocument, OnArticleDeleteByIdDocument],
  typeMappings: new Map<ArticleType, TypeMapping>([
    [
      ArticleType.Metadata,
      {
        typename: 'ArticleMetadata',
        fragment: ArticleMetadataFull,
        fragmentName: 'ArticleMetadataFull',
        defaults: ArticleMetadataDefaults,
      },
    ],
    [
      ArticleType.Document,
      {
        typename: 'ArticleDocument',
        fragment: ArticleDocumentFull,
        fragmentName: 'ArticleDocumentFull',
        defaults: ArticleDocumentDefaults,
      },
    ],
    [
      ArticleType.Guidelines,
      {
        typename: 'ArticleGuidelines',
        fragment: ArticleGuidelinesFull,
        fragmentName: 'ArticleGuidelinesFull',
        defaults: ArticleGuidelinesDefaults,
      },
    ],
    [
      ArticleType.Rscore,
      {
        typename: 'ArticleRScore',
        fragment: ArticleRScoreFull,
        fragmentName: 'ArticleRScoreFull',
        defaults: ArticleRScoreDefaults,
      },
    ],
    [
      ArticleType.Comment,
      {
        typename: 'ArticleComment',
        fragment: ArticleCommentFull,
        fragmentName: 'ArticleCommentFull',
        defaults: ArticleCommentDefaults,
      },
    ],
    [
      ArticleType.CommentReply,
      {
        typename: 'ArticleComment',
        fragment: ArticleCommentFull,
        fragmentName: 'ArticleCommentFull',
        defaults: ArticleCommentDefaults,
      },
    ],
    [
      ArticleType.RootData,
      {
        typename: 'ArticleRootData',
        fragment: ArticleRootDataFull,
        fragmentName: 'ArticleRootDataFull',
        defaults: ArticleRootDataDefaults,
      },
    ],
    [
      ArticleType.Post,
      {
        typename: 'ArticlePost',
        fragment: ArticlePostFull,
        fragmentName: 'ArticlePostFull',
        defaults: ArticlePostDefaults,
      },
    ],
    [
      ArticleType.PostAdvices,
      {
        typename: 'ArticlePostAdvices',
        fragment: ArticlePostAdvicesFull,
        fragmentName: 'ArticlePostAdvicesFull',
        defaults: ArticlePostAdvicesDefaults,
      },
    ],
  ]),
};

export const briefTableMapping: TableMapping = {
  createSubscriptions: [OnArticleBriefCreateDocument],
  updateSubscriptions: [OnArticleBriefUpdateDocument],
  deleteSubscriptions: [OnArticleBriefDeleteDocument],
  typeMappings: new Map<ArticleBriefType, TypeMapping>([
    [
      ArticleBriefType.Headline,
      {
        typename: 'ArticleHeadline',
        fragment: ArticleHeadlineFull,
        fragmentName: 'ArticleHeadlineFull',
        defaults: ArticleHeadlineDefaults,
      },
    ],
    [
      ArticleBriefType.Keyword,
      {
        typename: 'ArticleKeyword',
        fragment: ArticleKeywordFull,
        fragmentName: 'ArticleKeywordFull',
        defaults: ArticleKeywordDefaults,
      },
    ],
    [
      ArticleBriefType.Question,
      {
        typename: 'ArticleQuestion',
        fragment: ArticleQuestionFull,
        fragmentName: 'ArticleQuestionFull',
        defaults: ArticleQuestionDefaults,
      },
    ],
  ]),
};

export const mappings = [
  articleTableMapping,
  briefTableMapping,
  organizationTableMapping,
  projectTableMapping,
  userTableMapping,
  notificationTableMapping,
  uiViewTableMapping,
];
